import { HomeOutlined } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Button, ClickAwayListener, SvgIcon, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { downloadInstruction } from '../../apis';
import ClientRefDisplay from '../../components/ClientRefDisplay';
import { Cf, Nf, RawHtmlContent } from '../../components/Format';
import ShareModal from '../../components/ShareModal';
import { useDynamicColumns } from '../../hooks/responsiveHook';
import { Translate } from '../../i18n/translate';
import {
  selectData,
  selectHomeAmount,
  selectHomeCurrency,
  selectIsPaymentEventEnabled,
  selectShowReference,
  selectSsoRequest,
} from '../../store/slices/session/sessionSlice';
import { eventBus } from '../../utils/events/eventBus';
import { EPS_EVENT } from '../../utils/events/eventTypes';
import { getSsoInfo } from '../../utils/helper';
import {
  getAmount,
  getCurrency,
  getFee,
  getHomeAmount,
  getHomeCurrency,
} from '../../utils/quoteService';
import { HowToComplete } from './HowToComplete';
import { getTimeZone } from './Timezone';

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const OfflinePaymentInstruction = () => {
  const session = useSelector(selectData);
  const homeAmount = useSelector(selectHomeAmount);
  const homeCurrency = useSelector(selectHomeCurrency);
  const [pledge, setPledge] = useState({});
  const [, setSellerInfo] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [expiryDate, setExpiryDate] = useState('');
  const [hourString, setHourString] = useState('');
  const containerRef = useRef(null);
  const columns = useDynamicColumns(1, containerRef, 650);
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const ssoRequest = useSelector(selectSsoRequest);
  const { clientRef } = getSsoInfo(ssoRequest);
  const isEventPaymentEanabled = useSelector(selectIsPaymentEventEnabled);
  const showReference = useSelector(selectShowReference);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  useEffect(() => {
    setPledge(session.pledge);
    setSellerInfo(session.sellerInfo);
    const currentDate = new Date();
    const expirationDate = new Date(
      currentDate.getTime() + session.sellerInfo.expiry * 60 * 60 * 1000,
    );
    let hours = currentDate.getHours() % 12;
    hours = hours === 0 ? 12 : hours;
    hours = hours.toString().padStart(2, '0');
    let minutes = currentDate.getMinutes();
    minutes = minutes.toString().padStart(2, '0');
    const amPm = currentDate.getHours() >= 12 ? 'PM' : 'AM';
    const hourString = `${hours}:${minutes} ${amPm}`;
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = intl.formatDate(expirationDate, options);
    const formattedWithHyphens = formattedDate.replace(/ /g, '-');
    setExpiryDate(formattedWithHyphens);
    setHourString(hourString);
  }, [session]);

  const downloadFile = async () => {
    const response = await downloadInstruction();
    if (!response.success) {
      return;
    }
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'instruction.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReturnToSeller = () => {
    if (isEventPaymentEanabled) {
      eventBus.emit(EPS_EVENT.REDIRECT, {
        source: 'EPS_CONVERA',
        timestamp: new Date().getTime(),
        paymentMethod: session.selectedQuote.paymentType.id,
        clientRef: clientRef,
      });
    }
    if (session.returnUrl) {
      window.location.href = session.returnUrl;
    }
  };

  const renderCopyReference = (size = 'lg') => {
    if (size === 'sm') {
      return (
        <CopyToClipboard text={pledge.dealReference}>
          <div className=''>
            <div className='border-dashed border text-sm font-bold p-1 border-lilac mt-2 text-accent rounded inline-block'>
              {pledge.dealReference}
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={intl.formatMessage({ id: 'JS_COPIED_SUCCESS' })}
                  placement='top'
                >
                  <ContentCopyIcon
                    className='text-14 ml-1 cursor-pointer'
                    onClick={handleTooltipOpen}
                  />
                </Tooltip>
              </ClickAwayListener>
            </div>
          </div>
        </CopyToClipboard>
      );
    }
    return (
      <CopyToClipboard text={pledge.dealReference}>
        <div className='flex justify-center'>
          <div className='border-dashed border text-20 p-3 border-lilac mt-2 text-accent rounded'>
            {pledge.dealReference}
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={intl.formatMessage({ id: 'JS_COPIED_SUCCESS' })}
                placement='top'
              >
                <ContentCopyIcon
                  className='text-14 ml-1 cursor-pointer'
                  onClick={handleTooltipOpen}
                />
              </Tooltip>
            </ClickAwayListener>
          </div>
        </div>
      </CopyToClipboard>
    );
  };

  const today = new Date();
  const isDfCompliant = session.selectedServices.country === 'USA';

  return (
    <div className='pt-3 pb-3 container max-w-[750px]' ref={containerRef}>
      <h4 className='text-16-bold'>
        <Translate value={'JS_PAYMENT_SUMMARY'} />
      </h4>
      <div>
        <p className='border-b border-b-gray4 pt-3'>
          <span className='text-base'>
            {' '}
            <Translate value={'JS_YOU_ARE_PAYING'} />
          </span>
          <span className='text-16 font-mono float-right'>
            {Nf(session.selectedQuote.buyerAmount)} {Cf(session.selectedQuote.buyerCurrency)}
          </span>
        </p>
        <p className='border-b border-b-gray4 pt-3'>
          <span className='text-base'>
            <Translate value={'JS_INSTITUTION_WILL_RECEIVE'} />
          </span>
          <span className='text-16 font-mono float-right'>
            {Nf(homeAmount)} {Cf(homeCurrency)}
          </span>
        </p>
      </div>

      <ShareModal open={modalOpen} setOpen={setModalOpen} selectedQuote={session.selectedQuote} />
      <div className='p-4 bg-warning mt-3'>
        <InfoOutlinedIcon fontSize='23px' className='text-midnight w-6 h-6' />
        <Translate
          value='JS_OFFLINE_PAYMENT_INSTRUCTIONS_1'
          className='text-16 text-midnight ml-2 align-bottom'
        />
        <div className='mt-3'>
          <Translate
            value='JS_OFFLINE_PAYMENT_INSTRUCTIONS_2'
            className='text-16-bold text-black'
          />

          <div className={columns == 2 ? 'flex justify-between' : ''}>
            <div className='flex  mt-2 '>
              <div className='border-solid  p-2 border-x border-y border-r-0 rounded-l grow bg-white'>
                <CalendarTodayIcon className='text-midnight w-[20px] h-[20px] ml-1' />
                <span className='ml-3 text-18 align-sub'>{expiryDate}</span>
              </div>
              <div className='border-solid  p-2  border-x border-y  rounded-r grow bg-white'>
                <AccessTimeIcon className='text-midnight w-[20px] h-[20px] ml-1' />
                <span className='ml-3  text-18 align-sub'>
                  {hourString} {getTimeZone()}
                </span>
              </div>
            </div>

            <div className={` ${columns == 2 ? 'pr-8' : 'flex justify-center mt-2'}`}>
              <Button
                className='text-14 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px] bg-accent text-white'
                variant='contained'
                onClick={downloadFile}
              >
                <SvgIcon>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                  >
                    <path
                      d='M14.9999 12.5V15H4.99992V12.5H3.33325V15C3.33325 15.9167 4.08325 16.6667 4.99992 16.6667H14.9999C15.9166 16.6667 16.6666 15.9167 16.6666 15V12.5H14.9999ZM14.1666 9.16671L12.9916 7.99171L10.8333 10.1417V3.33337H9.16658V10.1417L7.00825 7.99171L5.83325 9.16671L9.99992 13.3334L14.1666 9.16671Z'
                      fill='white'
                    />
                  </svg>
                </SvgIcon>
                <Translate value='JS_DOWNLOAD_INSTRUCTION_BUTTON' className='ml-2' />
              </Button>
            </div>
          </div>

          <div
            className='mt-3.5 flex justify-center text-accent cursor-pointer'
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <ShareOutlinedIcon />
            <Translate value='JS_INSTRUCTION_EMAIL_SEND_BUTTON' className='ml-2' />
          </div>
        </div>
      </div>
      <div className='mt-5 text-center'>
        {/* <Translate value='JS_OFFLINE_REFERENCE' className='text-16-bold' /> */}
      </div>
      {!isDfCompliant && (
        <>
          {renderCopyReference()}
          {showReference && (
            <div className='mt-[27px]'>
              <ClientRefDisplay />
            </div>
          )}
        </>
      )}

      {isDfCompliant && (
        <>
          <div className='border border-lightGray p-4 mt-5'>
            <h4 className='text-14-bold mb-3'>
              <Translate value={'JS_BuyerDetails_Consol'} />
            </h4>
            <div className='grid grid-cols-1'>
              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_WP_REFERENCE' />
                <p>{renderCopyReference('sm')}</p>
              </div>

              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_WP_RECIPIENT' />
                <p>{session.sellerInfo.name}</p>
              </div>

              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_WP_DATE' />
                <p>{today.toLocaleDateString('en-GB', DATE_OPTIONS)}</p>
              </div>

              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_OFFLINE_PAYMENT_AMOUNT_RECEIVE' />
                <p>
                  <>
                    {Nf(getHomeAmount(session.selectedQuote))}
                    <span className='ml-1'>{Cf(getHomeCurrency(session.selectedQuote))}</span>
                  </>
                </p>
              </div>

              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_WP_STATUS' />
                <Translate value='JS_AWAITING_PAYMENT' />
              </div>

              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_WP_TOTAL_DUE' />

                <p>
                  <>
                    {Nf(getAmount(session.selectedQuote))}
                    <span className='ml-1'>{Cf(getCurrency(session.selectedQuote))}</span>
                  </>
                </p>
              </div>

              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_WP_METHOD' />
                <Translate value={`${session.selectedQuote.paymentType.id}-LABEL`} />
              </div>

              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_WP_TOTAL_FEES' />
                <p>
                  <>
                    {Nf(getFee(session.selectedQuote))}
                    <span className='ml-1'>{Cf(getHomeCurrency(session.selectedQuote))}</span>
                  </>
                </p>
              </div>

              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_WP_PAYER' />
                <p>{session.payerName}</p>
              </div>

              <div className='mb-3 text-sm flex items-center justify-between'>
                <Translate value='JS_DFCREC_LICENSEE' />
                <p>
                  <Translate value='JS_DFCREC_LICENSEE_DESC' />
                </p>
              </div>
            </div>
          </div>
          {showReference && (
            <div className='mt-[27px]'>
              <ClientRefDisplay />
            </div>
          )}
        </>
      )}

      {(session.returnUrl || isEventPaymentEanabled) && (
        <div className='text-center mt-5'>
          <Button
            className='text-14 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
            variant='contained'
            disableElevation
            onClick={handleReturnToSeller}
            color='secondary'
          >
            <HomeOutlined className='mr-1' />
            <span>
              <Translate value='Return to Homepage' />
            </span>
          </Button>
        </div>
      )}

      <div>
        <HowToComplete columns={columns} pledge={pledge} />
      </div>

      {isDfCompliant && (
        <div className='mt-5'>
          <RawHtmlContent htmlContent={'JS_DFCREC_COMPLAINTS'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_COMPLAINTS_DESC'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_TERMS_FOR_REMITTERS_NY'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_TERMS_FOR_REMITTERS_TEXAS'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_TERMS_FOR_REMITTERS_CALIFORNIA'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_RIGHT_TO_REFUND'} />
        </div>
      )}
    </div>
  );
};
