import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CountrySelect } from '../../components/CountryDropDown';
import DatePicker from '../../components/DatePicker';
import DocUploader from '../../components/DocumentUploader';
import DocUploadInfo from '../../components/DocumentUploader/docUploadInfo';
import { RawHtmlContent } from '../../components/Format';
import InputWithLabel from '../../components/InputWithLabel';
import SmartAddress from '../../components/SmartAddress';
import { useDynamicColumns } from '../../hooks/responsiveHook';
import { Translate } from '../../i18n/translate';
import {
  selectAllCountries,
  selectSmartyEnabledCountries,
} from '../../store/slices/refdata/refdataSlice';
import {
  selectConsolBuyerRemitterPageEnabled,
  selectData,
  selectLoading,
} from '../../store/slices/session/sessionSlice';
import ConslidatedContext from '../ConsolidatedPage/consolidatedContext';
import { ERROR_MESSAGES, PATTERNS } from '../PayerDetail/formHelper';
import {selectRefDataCache} from '../../store/slices/refdata/refdataSlice';
import {geValidRegex} from '../../utils/helper';

const customFieldTypes = {
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
  SELECT: 'SELECT',
  COUNTRY: 'COUNTRY',
  CHECK_BOX: 'CHECK_BOX',
  RADIO_BUTTONS: 'RADIO_BUTTONS',
  DATE: 'DATE',
};

const StudentForm = ({
  customFields,
  validate,
  documents,
  updateDoc,
  documentsVerified,
  buyerData,
  missingAllowedFields,
  isPaymentFieldVisible,
  onlineCourseEnrolment,
  setOnlineCourseEnrollment,
  getDynamicFieldLabel,
}) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [validations, setValidations] = useState({});
  const [isFormValid, setFormValid] = useState(false);
  const [isSmartyEnabled, setSmartyEnabled] = useState(false);
  const session = useSelector(selectData);
  const refDataCache = useSelector(selectRefDataCache);
  const containerRef = useRef(null);
  const columns = useDynamicColumns(1, containerRef, 700);
  const intl = useIntl();
  const allCountries = useSelector(selectAllCountries);
  const consolBuyerRemitterPageEnabled = useSelector(selectConsolBuyerRemitterPageEnabled);
  const consolidatedContext = useContext(ConslidatedContext);
  let setIsStudentFormValid;
  let buyerSubmitRef;
  let isPayerFormValid;
  if (consolidatedContext) {
    ({ setIsStudentFormValid, buyerSubmitRef, isPayerFormValid } = consolidatedContext);
  }
  const smartyEnabledCountries = useSelector(selectSmartyEnabledCountries);
  const isAPIInProgress = useSelector(selectLoading);

  useEffect(() => {
    const _isSmartyEnabled =
      session.selectedQuote.paymentType.smartyEnabled &&
      session.sellerInfo.isSmartyEnabledClient &&
      smartyEnabledCountries.includes(formData.country);
    setSmartyEnabled(_isSmartyEnabled);
  }, [formData.country]);

  useEffect(() => {
    createFormData();
  }, [buyerData, isSmartyEnabled]);

  useEffect(() => {
    hasValidationErrors();
  }, [formData, errors, validations]);

  useEffect(() => {
    //set student form valid value in conslidated form
    if (consolBuyerRemitterPageEnabled) {
      setIsStudentFormValid(isFormValid && documentsVerified);
    }
  }, [documentsVerified, isFormValid]);


  const getValidations = (item) => {
    if (
      !isAddressComplianceField(item) &&
      !(!session.sso || session.buyerCanEdit === true || parentForceEditable())
    ) {
      return {};
    }
    let validationObj = {};
    let mandatory = item.mandatory;
    if (item.type === customFieldTypes.TEXT) {
      mandatory =
        !(isSso() && !forceEditable(item.name, buyerData[item.name]?.value)) && item.mandatory;
      if (forceIgnorable(item.name) || isAddressComplianceField(item)) {
        mandatory = item.mandatory;
      }
    }
    if (mandatory) {
      validationObj['required'] = getTranslatedErrorText(item, ERROR_MESSAGES.required);
    }

    if (item.regex) {
      validationObj['pattern'] = {
        value: new RegExp(item.regex),
        message: getTranslatedErrorText(item, ERROR_MESSAGES.PATTERN),
      };
    }

    if (item.email) {
      validationObj['pattern'] = {
        value: PATTERNS.email,
        message: getTranslatedErrorText(item, ERROR_MESSAGES.email),
      };
    }

    if (item.minLength) {
      validationObj['minLength'] = {
        value: item.minLength,
        message: getTranslatedErrorText(item, undefined) ?? (
          <RawHtmlContent
            htmlContent={'PM_MIN_CHAR_ERROR'}
            translateValues={{ minLength: item.minLength }}
          />
        ),
      };
    }

    if (item.name === 'businessId' && item.regex) {
      validationObj['pattern'] = {
        value: new RegExp(item.regex.replace(']', '\\_]')),
        message: getTranslatedErrorText(item, ERROR_MESSAGES.PATTERN),
      };
    }

    if (!validationObj.pattern && item.type === customFieldTypes.TEXT) {
      validationObj['pattern'] = {
        value: PATTERNS.SWIFT_REGEX,
        message: ERROR_MESSAGES.SWIFT_REGEX,
      };
    }
    if (regexOverride(item) && !item.regex){
      validationObj['pattern'] = {
        value: new RegExp(geValidRegex(refDataCache)),
        message: ERROR_MESSAGES.SWIFT_REGEX,
      };
    }
    return validationObj;
  };

  const isAddressComplianceField = (item) =>
    ['address', 'address2', 'city', 'state', 'zip', 'country'].includes(item.name);

  const regexOverride = (item) =>
    ['firstName','lastName'].includes(item.name);

  const getTranslatedHelpText = (customField) => {
    var translatedText = getTranslatedValue(customField.helpKey);
    return customField.name + '_HELP' === translatedText ? '' : translatedText;
  };
  const getTranslatedErrorText = (customField, alternateErrorMsg = '') => {
    var translatedText = getTranslatedValue(customField.errorKey);
    return customField.name + '_ERROR' === translatedText ? alternateErrorMsg : translatedText;
  };

  const getTranslatedValue = (label) => {
    return intl.formatMessage({ id: label });
  };

  const createFormData = () => {
    let formDataTemp = {};
    let formErrorsTemp = {};
    let formValidationsTemp = {};
    customFields.map((item) => {
      formDataTemp[item.name] = buyerData[item.name] ? buyerData[item.name].value : '';
      if (forceIgnorable(item.name)) {
        formDataTemp[item.name] = '';
      }
      formErrorsTemp[item.name] = '';
      formValidationsTemp[item.name] = getValidations(item);
    });
    if (!formDataTemp['country']) {
      formDataTemp['country'] = session.selectedServices.country;
    }
    setFormData(formDataTemp);
    setErrors(formErrorsTemp);
    setValidations(formValidationsTemp);
    if (!(!session.sso || session.buyerCanEdit === true || parentForceEditable())) {
      setFormValid(true);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, ''),
    });
  };

  const setCountry = (val, name) => {
    setFormData({
      ...formData,
      [name]: val,
    });
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const error = validateField(name, value);
    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const hasValidationErrors = () => {
    let formValid = Object.keys(validations).some((fieldName) => {
      const error = validateField(fieldName, formData[fieldName], true);
      return !!error;
    });
    setFormValid(!formValid);
  };

  const showAllValidationErrors = () => {
    let allErrors = {};
    Object.keys(validations).map((fieldName) => {
      const error = validateField(fieldName, formData[fieldName]);
      allErrors[fieldName] = error;
    });

    setErrors({
      ...errors,
      ...allErrors,
    });
  };

  const validateField = (name, value) => {
    const fieldValidations = validations[name];
    if (typeof value === 'string') {
      value = value.trim();
    }
    let error = '';
    if (fieldValidations) {
      if (
        fieldValidations.required &&
        ((typeof value === 'string' && !value) ||
          (typeof value === 'boolean' && !value) ||
          value == undefined)
      ) {
        error = fieldValidations.required;
      } else if (
        !_.isNil(value) &&
        fieldValidations.pattern &&
        !fieldValidations.pattern.value.test(value)
      ) {
        error = fieldValidations.pattern.message;
      } else if (
        fieldValidations.minLength &&
        value &&
        value.length < fieldValidations.minLength.value
      ) {
        error = fieldValidations.minLength.message;
      }

      //TODO: if date, handle min date, max date, invalid date
    }

    return error;
  };

  const isSso = () => {
    return session.sso && session.buyerCanEdit !== true;
  };

  const isWebIntegrationFieldsIgnorable = () => {
    return (
      isSso() &&
      session.sellerInfo.webIntegrationEnabled &&
      session.sellerInfo.isWebIntegrationFieldsIgnorable
    );
  };

  const isWebIntegrationFieldsEditable = () => {
    return (
      isSso() &&
      session.sellerInfo.webIntegrationEnabled &&
      session.sellerInfo.isWebIntegrationFieldsEditable
    );
  };

  const forceEditable = (fieldName, fieldValue) => {
    return (
      (isWebIntegrationFieldsEditable() && isAllowedEditableField(fieldName)) ||
      (isAllowedIgnorableField(fieldName) && !fieldValue) ||
      (isAllowedField(fieldName) && !fieldValue)
    );
  };

  const parentForceEditable = () => {
    return (
      isWebIntegrationFieldsEditable() || isWebIntegrationFieldsIgnorable() || missingAllowedFields
    );
  };

  const isAllowedEditableField = (fieldName) => {
    var allowedFields = [
      'invoiceNumber',
      'firstName',
      'lastName',
      'address',
      'city',
      'state',
      'zip',
      'email',
    ];
    return _.includes(allowedFields, fieldName) || _.startsWith(fieldName, 'custom');
  };
  const isAllowedIgnorableField = (fieldName) => {
    var allowedFields = ['firstName', 'lastName'];
    return _.includes(allowedFields, fieldName);
  };
  const isAllowedField = (fieldName) => {
    var allowedFields = ['email'];
    return _.includes(allowedFields, fieldName);
  };

  const forceIgnorable = (fieldName) => {
    return isWebIntegrationFieldsIgnorable() && isAllowedIgnorableField(fieldName);
  };

  const proceed = () => {
    if (!isFormValid || !documentsVerified) {
      showAllValidationErrors();
      return;
    }

    if (consolBuyerRemitterPageEnabled && !isPayerFormValid) return;

    validate(formData);
  };

  const getLabelKey = (fieldName, apiValue) => {
    const map = {
      address: 'SM_ADDRESS_1_LABEL',
      address2: 'SM_ADDRESS_2_LABEL',
      city: 'SM_CITY_LABEL',
      state: 'SM_STATE_LABEL',
      country: 'SM_COUNTRY_LABEL',
      zip: 'SM_ZIP_LABEL',
    };
    return map[fieldName] ?? apiValue;
  };

  return (
    <>
      <div ref={containerRef} className={`grid-${columns} gap-x-4`}>
        {customFields.map((customField, index) => {
          let required = customField.mandatory;
          let disabled =
            isSso() && !forceEditable(customField.name, buyerData[customField.name]?.value);
          if (forceIgnorable(customField.name)) {
            disabled = false;
          }
          // address comliance changes- make address field editable
          if (isAddressComplianceField(customField)) {
            /* compliance fields */
            disabled = false;
          }
          if ([customFieldTypes.TEXT, customFieldTypes.EMAIL].includes(customField.type)) {
            return (
              <InputWithLabel
                key={index}
                label={getLabelKey(customField.name, customField.labelKey)}
                required={required}
              >
                {isSmartyEnabled & (customField.name == 'address') ? (
                  <SmartAddress
                    placeholder={intl.formatMessage({
                      id: getLabelKey(customField.name, customField.labelKey),
                    })}
                    addressValue={formData['address']}
                    label={''}
                    country={formData['country']}
                    setFormData={(data) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        ...data,
                      }));
                      const addressError = data['address']
                        ? validateField('address', data['address'])
                        : '';
                      const address2Error = data['address2']
                        ? validateField('address2', data['address2'])
                        : '';
                      const cityError = data['city'] ? validateField('city', data['city']) : '';
                      const stateError = data['state'] ? validateField('state', data['state']) : '';
                      const zipError = data['zip'] ? validateField('zip', data['zip']) : '';

                      setErrors({
                        ...errors,
                        address: addressError,
                        address2: address2Error,
                        city: cityError,
                        state: stateError,
                        zip: zipError,
                      });
                    }}
                    onBlur={() => {
                      const addressError = validateField('address', formData['address']);
                      setErrors({
                        ...errors,
                        address: addressError,
                      });
                    }}
                    helperText={
                      !errors['address'] ? getTranslatedHelpText(customField) : errors['address']
                    }
                    error={!!errors['address']}
                  />
                ) : (
                  <TextField
                    label={''}
                    name={customField.name}
                    value={formData[customField.name]}
                    variant='outlined'
                    inputProps={{
                      placeholder: intl.formatMessage({
                        id: getLabelKey(customField.name, customField.labelKey),
                      }),
                      maxLength: customField.maxLength,
                    }}
                    error={!!errors[customField.name]}
                    helperText={
                      !errors[customField.name]
                        ? getTranslatedHelpText(customField)
                        : errors[customField.name]
                    }
                    fullWidth
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                  />
                )}
              </InputWithLabel>
            );
          } else if (
            customField.name !== 'debtorGroup' &&
            customField.type == customFieldTypes.SELECT
          ) {
            return (
              <InputWithLabel key={index} label={customField.labelKey} required={required}>
                <FormControl fullWidth error={!!errors[customField.name]}>
                  <Select
                    name={customField.name}
                    value={formData[customField.name]}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    required={required}
                    disabled={disabled}
                    error={!!errors[customField.name]}
                    sx={{
                      '& .MuiSelect-select .notranslate::after': getTranslatedValue(
                        customField.labelKey,
                      )
                        ? {
                            content: `"${getTranslatedValue(customField.labelKey)}"`,
                            opacity: 0.42,
                          }
                        : {},
                    }}
                  >
                    {customField.values.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        <Translate value={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {!errors[customField.name] && (
                  <FormHelperText>{getTranslatedHelpText(customField)}</FormHelperText>
                )}

                {!!errors[customField.name] && (
                  <FormHelperText error>{errors[customField.name]}</FormHelperText>
                )}
              </InputWithLabel>
            );
          } else if (customField.type === customFieldTypes.COUNTRY) {
            return (
              <InputWithLabel
                key={index}
                label={getLabelKey(customField.name, customField.labelKey)}
                required={required}
              >
                <CountrySelect
                  countries={allCountries}
                  setCountry={(val) => {
                    if (val !== formData.country && customField.name === 'country') {
                      const buyerData = {
                        address: '',
                        address2: '',
                        city: '',
                        zip: '',
                        state: '',
                        [customField.name]: val,
                      };
                      setFormData((prevState) => ({
                        ...prevState,
                        ...buyerData,
                      }));

                      setErrors({
                        ...errors,
                        address: '',
                        address2: '',
                        city: '',
                        zip: '',
                        state: '',
                      });
                    } else {
                      setCountry(val, customField.name);
                    }
                  }}
                  value={buyerData.country?.value}
                  disabled={disabled}
                  error={!!errors[customField.name]}
                />
              </InputWithLabel>
            );
          } else if (customField.type == customFieldTypes.CHECK_BOX) {
            return (
              <div className='mb-2' key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={customField.name}
                      checked={formData[customField.name]}
                      onChange={handleInputChange}
                      required={required}
                      disabled={disabled}
                      error={!!errors[customField.name]}
                    />
                  }
                  label={getTranslatedValue(customField.labelKey)}
                />
                {!errors[customField.name] && (
                  <FormHelperText sx={{ marginTop: '0px' }}>
                    {getTranslatedHelpText(customField)}
                  </FormHelperText>
                )}
                {!!errors[customField.name] && (
                  <FormHelperText error>{errors[customField.name]}</FormHelperText>
                )}
              </div>
            );
          } else if (customField.type == customFieldTypes.RADIO_BUTTONS) {
            return (
              <div className='mb-2' key={index}>
                <FormControl>
                  <FormLabel>{getTranslatedValue(customField.labelKey)}</FormLabel>
                  <RadioGroup name={customField.name}>
                    {customField.values.map((option, index) => (
                      <FormControlLabel
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        key={index}
                        required={required}
                        disabled={disabled}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            );
          } else if (customField.type == customFieldTypes.DATE) {
            return (
              <div className='mb-2' key={index}>
                <DatePicker
                  {...customField}
                  value={formData[customField.name]}
                  handleChange={handleInputChange}
                  handleBlur={handleBlur}
                  error={!!errors[customField.name]}
                  helperText={errors[customField.name]}
                  required={required}
                  disabled={disabled}
                />

                {!errors[customField.name] && (
                  <FormHelperText>{getTranslatedHelpText(customField)}</FormHelperText>
                )}
                <FormHelperText sx={{ marginTop: '0px' }}>
                  <span>(</span> <Translate value={'inputDateFormatLabel'} /> <span>)</span>
                </FormHelperText>
              </div>
            );
          }
        })}

        {/* TODO: Pass in payment type ID to docUploadInfo, so that this component knows which text to show */}
        {/* NOTE: Doc upload in student detail only applies to VP bank */}
        {documents.length > 0 && (
          <>
            <div>
              <div className='mt-1 mb-2'>
                <DocUploadInfo paymentTypeId={documents[0].paymentTypeId} />
              </div>
              {isPaymentFieldVisible('onlineCourseEnrolment') && (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='onlineCourseEnrolment'
                        checked={onlineCourseEnrolment}
                        onChange={setOnlineCourseEnrollment}
                        required={false}
                        disabled={false}
                      />
                    }
                    label='Are you enrolled in an online course?'
                  />
                </div>
              )}
            </div>
            <div>
              {documents.map((doc, index) => (
                <DocUploader
                  {...doc}
                  updateDoc={(data) => updateDoc(index, data)}
                  key={index}
                  getDynamicFieldLabel={getDynamicFieldLabel}
                />
              ))}
            </div>
          </>
        )}
      </div>

      <div
        className={`mt-5 text-center border-t border-t-lightGray2 pt-3 ${
          consolBuyerRemitterPageEnabled ? 'hidden' : ''
        }`}
      >
        <Button
          id='buyerSubmit'
          ref={buyerSubmitRef}
          className={`text-14 ${
            !isFormValid || !documentsVerified || isAPIInProgress
              ? 'opacity-90 bg-disabled shadow-none text-disabledFont'
              : ''
          }`}
          variant='contained'
          disabled={isAPIInProgress}
          onClick={() => proceed()}
        >
          <span>
            Next&nbsp;
            <ArrowForwardIcon />
          </span>
        </Button>

        <div className='text-16 italic mt-3 flex justify-center '>
          <Translate value='JS_NextPayerDetails' />
        </div>
      </div>
    </>
  );
};

export default StudentForm;
