import { HomeOutlined } from '@mui/icons-material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Button, ClickAwayListener, Tooltip } from '@mui/material';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ClientRefDisplay from '../../components/ClientRefDisplay';
import { Cf, Nf, RawHtmlContent } from '../../components/Format';
import ShareModal from '../../components/ShareModal';
import { Translate } from '../../i18n/translate';
import { ROUTE_MAPPING, navigate } from '../../store/slices/router/routerSlice';
import {
  selectData,
  selectIsPaymentEventEnabled,
  selectLocale,
  selectShowReference,
  selectSsoRequest,
  selectTransactionStatus,
  setPaymentFlowComplete,
} from '../../store/slices/session/sessionSlice';
import { eventBus } from '../../utils/events/eventBus';
import { EPS_EVENT } from '../../utils/events/eventTypes';
import { getSsoInfo } from '../../utils/helper';
import { imagePath } from '../../utils/imgHelper';
import { getAmount, getCurrency, getHomeAmount, getHomeCurrency } from '../../utils/quoteService';
import './orderReceipt.scss';

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const OrderReceipt = () => {
  const orderReceiptRef = useRef();
  const dispatch = useDispatch();
  const session = useSelector(selectData);
  const transactionStatus = useSelector(selectTransactionStatus);
  const [imgSrc, setImgSrc] = useState();

  const [open, setOpen] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [reference, setReference] = useState('');
  const [isPdf, setIsPdf] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const ssoRequest = useSelector(selectSsoRequest);
  const { clientRef } = getSsoInfo(ssoRequest);
  const isEventPaymentEanabled = useSelector(selectIsPaymentEventEnabled);
  const locale = useSelector(selectLocale);
  const showReference = useSelector(selectShowReference);
  const intl = useIntl();

  useEffect(() => {
    const amount1 = getAmountInPaymentCurrency();
    const amount2 = getTotalAmount();
    setImgSrc(
      `${process.env.REACT_APP_BUYER_URL}/geo-buyer/_assets/logo?asset=${session.selectedQuote.paymentType.id}_ICON`,
    );

    let t_details = [
      { fieldName: 'JS_WP_STATUS', fieldValue: 'JS_WP_APPROVED', type: 'i18n' },
      { fieldName: 'JS_WP_PAYER', fieldValue: session.payerName },
      { fieldName: 'JS_WP_RECIPIENT', fieldValue: session.sellerInfo.name },
      {
        fieldName: 'JS_WP_METHOD',
        fieldValue: '',
        type: 'image',
      },
      { fieldName: 'JS_WP_PAYMENT', fieldValue: amount1 },
      { fieldName: 'JS_WP_HOME_PAYMENT', fieldValue: amount2 },
    ];

    if (session.ecbDifference) {
      t_details.push({
        fieldName: 'JS_ECB_DIFF',
        fieldValue: `${parseFloat(session.ecbDifference).toFixed(2)}%`,
      });
    }

    setTransactionDetails(t_details);

    setReference(session.pledge.dealReference);
  }, []);

  const getAmountInPaymentCurrency = () => {
    return (
      <>
        {Nf(getAmount(session.selectedQuote))}
        <span className='ml-1'>{Cf(getCurrency(session.selectedQuote))}</span>
      </>
    );
  };

  const getTotalAmount = () => {
    return (
      <>
        {Nf(getHomeAmount(session.selectedQuote))}
        <span className='ml-1'>{Cf(getHomeCurrency(session.selectedQuote))}</span>
      </>
    );
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const downloadPDF = () => {
    console.log('Download process started');
    if (!orderReceiptRef.current) {
      console.error('Error: orderReceiptRef is null or undefined');
      return;
    }
    console.log('orderReceiptRef:', orderReceiptRef.current);
    setIsPdf(true);
    console.log('Set isPdf to true');
    var opt = {
      margin: 1,
      marginBottom: 1,
      filename: 'order-receipt.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    };
    console.log('PDF options:', opt);
    try {
      html2pdf(orderReceiptRef.current, opt)
        .then(() => {
          console.log('PDF download successful');
          setIsPdf(false);
        })
        .catch((error) => {
          console.error('Error during PDF generation:', error);
          setIsPdf(false);
        });
    } catch (error) {
      console.error('Unexpected error in downloadPDF function:', error);
      setIsPdf(false);
    }
    console.log('Download process completed');
  };

  const handleImageError = () => {
    setImgSrc(
      `${process.env.REACT_APP_BUYER_URL}/geo-buyer/_assets/logo?asset=${session.selectedQuote.paymentType.id}_LOGO`,
    );
  };

  const retryPayment = () => {
    dispatch(setPaymentFlowComplete(false));
    dispatch(navigate({ route: ROUTE_MAPPING.PAYMENT_METHOD }));
  };

  const transactionDate = new Date();
  const isDfCompliant = session.selectedServices.country === 'USA';
  const trackingUrl = () => {
    return `${process.env.REACT_APP_BUYER_URL}#!/tracking?tracking=PAYMENT&reference=${reference}`;
  };

  const handleReturnToSeller = () => {
    if (isEventPaymentEanabled) {
      eventBus.emit(EPS_EVENT.REDIRECT, {
        source: 'EPS_CONVERA',
        timestamp: new Date().getTime(),
        paymentMethod: session.selectedQuote.paymentType.id,
        clientRef: clientRef,
      });
    }
    if (session.returnUrl) {
      window.location.href = session.returnUrl;
    }
  };

  return (
    <div className={'pt-3 pb-3  max-w-[750px] m-auto'}>
      <div id='order-receipt' ref={orderReceiptRef}>
        <div>
          <p className='text-16-bold mt-3 text-left'>
            <Translate value='PM_ORDER_RECEIPT' />
            {locale && (
              <span className='text-14 float-right'>
                {transactionDate
                  .toLocaleDateString(locale.replace('_', '-'), DATE_OPTIONS)
                  .replace(/\s+/g, '-')}
              </span>
            )}
          </p>
          <br />
        </div>
        {transactionStatus === 'SUCCESS' && (
          <div className='flex'>
            <img
              src={imagePath('icons/ok.svg')}
              style={{ height: 60, width: 60 }}
              alt='success-image'
            />
            <div className='mx-2'>
              <h4 className='text-16-bold mt-3'>
                <Translate value='PM_RECEIPT_SUCCESS_HEADING' />
              </h4>
              <RawHtmlContent
                htmlContent='PM_RECEIPT_SUCCESS_DESC'
                translateValues={{
                  trackingLink: trackingUrl(),
                }}
              />
            </div>
          </div>
        )}
        {transactionStatus === 'FAILED' && (
          <div className='flex'>
            <img
              src={imagePath('icons/failed.svg')}
              style={{ height: 60, width: 60 }}
              alt='failed-image'
            />
            <div className='mx-2'>
              <h4 className='text-16-bold mt-3'>
                <Translate value='PM_RECEIPT_FAILED_HEADING' />
              </h4>

              <RawHtmlContent
                htmlContent='PM_RECEIPT_FAILED_DESC'
                translateValues={{
                  trackingLink: trackingUrl(),
                }}
              />
            </div>
          </div>
        )}
        {transactionStatus === 'RATE_EXPIRED' && (
          <div>
            <div className='flex'>
              <img
                src={imagePath('icons/failed.svg')}
                style={{ height: 60, width: 60 }}
                alt='failed-image'
              />
              <div className='mx-2'>
                <h4 className='text-16-bold mt-3'>
                  <Translate value='PM_RECEIPT_RATE_EXPIRED_HEADING' />
                </h4>
                <RawHtmlContent
                  htmlContent='PM_RECEIPT_RATE_EXPIRED_DESC'
                  translateValues={{
                    trackingLink: trackingUrl(),
                  }}
                />
              </div>
            </div>
            <div className='text-center'>
              <Button
                variant='contained'
                onClick={retryPayment}
                className='text-14 !py-3 text-14 mt-16'
              >
                <Translate value='JS_NEW_PAYMENT' />
              </Button>
            </div>
          </div>
        )}
        {transactionStatus === 'PROCESSING' && (
          <div className='flex'>
            <img
              src={imagePath('icons/inprogress.svg')}
              style={{ height: 60, width: 60 }}
              alt='inprogress-image'
            />
            <div className='mx-2'>
              <h4 className='text-16-bold mt-3'>
                <Translate value='PM_RECEIPT_PROCESSING_HEADING' />
              </h4>
              <RawHtmlContent
                htmlContent='PM_RECEIPT_PROCESSING_DESC'
                translateValues={{
                  trackingLink: trackingUrl(),
                }}
              />
            </div>
          </div>
        )}
        {transactionStatus === 'COMPLIANCE_FAILED' && (
          <div className='flex'>
            <img
              src={imagePath('icons/failed.svg')}
              style={{ height: 60, width: 60 }}
              alt='failed-image'
            />
            <div className='mx-2'>
              <h4 className='text-16-bold mt-3'>
                <Translate value='PM_COMPLIANCE_FAILED_HEADING' />
              </h4>

              <RawHtmlContent htmlContent='PM_COMPLIANCE_FAILED_DESC' />
            </div>
          </div>
        )}
        <div>
          <h4 className='text-base font-bold mt-3 flex justify-center items-center'>
            <Translate value='PM_VIEW_TRANSACTION_STATUS' />
          </h4>
        </div>
        <CopyToClipboard text={reference}>
          <div className='flex justify-center items-center'>
            <p
              className='text-accent text-xl border-dotted border-2 p-2 cursor-pointer mt-4 flex justify-center'
              style={{ fontSize: '20px', alignItems: 'center' }}
            >
              <span>{reference}</span>
              <ClickAwayListener onClickAway={handleTooltipClose} data-html2canvas-ignore='true'>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={intl.formatMessage({ id: 'JS_COPIED_SUCCESS' })}
                  placement='top'
                  data-html2canvas-ignore='true'
                >
                  <ContentCopyOutlinedIcon
                    className='text-14 ml-1 cursor-pointer'
                    onClick={handleTooltipOpen}
                  />
                </Tooltip>
              </ClickAwayListener>
            </p>
          </div>
        </CopyToClipboard>
        {showReference && (
          <div className='mt-[27px]'>
            <ClientRefDisplay />
          </div>
        )}
        {/* Retry payment button */}
        {transactionStatus === 'FAILED' && !session.returnUrl && !isEventPaymentEanabled && (
          <div className='text-center'>
            <Button
              variant='contained'
              onClick={retryPayment}
              className='text-14 !py-3 text-14 mt-16'
            >
              <Translate value='JS_NEW_PAYMENT' />
            </Button>
          </div>
        )}
        {(transactionStatus === 'SUCCESS' || transactionStatus === 'PROCESSING') && (
          <div className='mt-6'>
            {transactionDetails.map((val, key) => {
              return (
                <div key={key} className='flex justify-between mt-2 items-center'>
                  <p className='text-left text-sm'>
                    <Translate value={val.fieldName} />
                  </p>
                  {!val.type && <p className='text-right text-14-bold'>{val.fieldValue}</p>}
                  {val.type == 'i18n' && (
                    <p className='text-right text-14-bold'>
                      <Translate value={val.fieldValue} />
                    </p>
                  )}
                  {val.type === 'image' && !isPdf && (
                    <p className='text-right text-14-bold' data-html2canvas-ignore='true'>
                      <img
                        src={imgSrc}
                        alt=''
                        className='w-24 text-right h-[35px] object-contain'
                        onError={handleImageError}
                      />
                    </p>
                  )}
                  {/* Show this only in pdf */}
                  {val.type === 'image' && isPdf && (
                    <p className='text-right text-14-bold'>
                      <Translate value={session.selectedQuote.paymentType.id + '-LABEL'} />
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <br />{' '}
      </div>
      {(transactionStatus === 'SUCCESS' || transactionStatus === 'PROCESSING') && (
        <div className='flex justify-center items-center mt-2'>
          <Button
            startIcon={<FileDownloadOutlinedIcon />}
            className='text-14  font-medium rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
            variant='contained'
            onClick={downloadPDF}
          >
            <Translate value='PM_DOWNLOAD_RECEIPT' />
          </Button>
        </div>
      )}
      <ShareModal
        open={modalOpen}
        setOpen={setModalOpen}
        selectedQuote={session.selectedQuote}
        isOffline={false}
      />
      {(transactionStatus === 'SUCCESS' || transactionStatus === 'PROCESSING') && (
        <div
          className='flex justify-center items-center mt-3 cursor-pointer'
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <ShareOutlinedIcon style={{ height: 16, width: 16 }} className='text-accent' />
          <span className='text-base mx-2 text-accent'>
            <Translate value='JS_RECIEPT_SHARE_TITLE' />
          </span>
        </div>
      )}

      {(session.returnUrl || isEventPaymentEanabled) && (
        <div className='mt-5 text-center'>
          <Button
            variant='text'
            onClick={() => handleReturnToSeller()}
            sx={{
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
              },
              '&.MuiButtonBase-root.Mui-disabled': {
                bgcolor: 'transparent',
                opacity: 0.5,
              },
            }}
            className='text-accent p-0 mr-5'
          >
            <HomeOutlined className='mr-1' />
            <Translate value='Return to Homepage' />
          </Button>
        </div>
      )}
      {isDfCompliant && (
        <div className='mt-5'>
          <RawHtmlContent htmlContent={'JS_DFCREC_COMPLAINTS'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_COMPLAINTS_DESC'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_TERMS_FOR_REMITTERS_NY'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_TERMS_FOR_REMITTERS_TEXAS'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_TERMS_FOR_REMITTERS_CALIFORNIA'} />
          <RawHtmlContent htmlContent={'JS_DFCREC_RIGHT_TO_REFUND'} />
        </div>
      )}
    </div>
  );
};
