import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { subscribeNotification } from '../../apis';
import { Translate } from '../../i18n/translate';
import { selectDialCodes } from '../../store/slices/refdata/refdataSlice';
import { selectData } from '../../store/slices/session/sessionSlice';

const SmsUpdate = ({ pledgeId }) => {
  const dialCodes = useSelector(selectDialCodes);
  const [dialCode, selectDialCode] = useState(null);
  const [error, setError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [defaultChecked, setDefaultChecked] = useState(true);
  const [sortedDialCodes, setSortDialCodes] = useState([]);
  const [success, setSuccess] = useState(false);
  const session = useSelector(selectData);
  const intl = useIntl();

  useEffect(() => {
    let _sortedDialCodes = [...dialCodes].sort((a, b) => {
      let aTranslated = intl.formatMessage({ id: a.countryCode });
      let bTranslated = intl.formatMessage({ id: b.countryCode });
      return aTranslated.localeCompare(bTranslated);
    });
    _sortedDialCodes = _sortedDialCodes.filter((c) => c.smsEnabled);
    setSortDialCodes(_sortedDialCodes);
  }, [dialCodes]);

  useEffect(() => {
    if (session && sortedDialCodes) {
      const buyerCountry =
        session.buyerDetails && isSmsEnabled(session.buyerDetails.country)
          ? session.buyerDetails.country
          : undefined;
      if (buyerCountry) {
        const payerCode = sortedDialCodes.filter((c) => c.countryCode == buyerCountry);
        selectDialCode(payerCode[0]);
      }
    }
  }, [session, sortedDialCodes]);

  const isSmsEnabled = (country) => {
    return _.chain(sortedDialCodes)
      .find(function (c) {
        return c.countryCode === country;
      })
      .get('smsEnabled')
      .value();
  };

  const handleChange = () => {
    setDefaultChecked(true);
  };

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const submit = async () => {
    setError('');
    setSuccess(false);
    let response = await subscribeNotification(phoneNumber, dialCode.countryCode, pledgeId);
    if (response.success) {
      response = response.data;
      if (response.validationResult.success) {
        setSuccess(true);
      } else {
        setError(response.validationResult.errors[0].errorCode);
      }
    }
  };

  return (
    <div className='flex justify-center mt-2'>
      <div className='max-w-[403px]'>
        <div className='mb-3'>
          <Translate value={'JS_PAYER_PHONE_CONTACT'} className={'text-16-bold'} />
        </div>
        <label className='text-sm mb-1 block'>
          <Translate value={'JS_OFF_PHONE_NO_LABEL'} />
        </label>
        <FormControl fullWidth>
          <div className='flex h-[48px]'>
            <Select
              labelId='demo-controlled-open-select-label'
              id='demo-controlled-open-select'
              className='rounded-tr-none rounded-br-none'
              value={dialCode}
              onBlur={handleChange}
              onChange={(e) => selectDialCode(e.target.value)}
              renderValue={(dialCode) => {
                const flagClassName = `gpfs-flag ${dialCode.countryCode.toLowerCase()}`;
                return (
                  <div className=''>
                    <i key={dialCode.countryCode} className={flagClassName}></i>
                    <span className='ml-3'>+{dialCode.dialingCode}</span>
                  </div>
                );
              }}
            >
              {sortedDialCodes.map((option, index) => {
                const flagClassName = `gpfs-flag ${option.countryCode.toLowerCase()}`;
                return (
                  <MenuItem key={index} value={option}>
                    <i key={option.countryCode} className={flagClassName}></i>
                    <Translate value={option.countryCode} />
                  </MenuItem>
                );
              })}
            </Select>
            <TextField
              id='filled-start-adornment'
              className='w-full tel-input h-[15px]'
              variant='outlined'
              error={error}
              value={phoneNumber}
              onKeyDown={(e) => {
                if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
                  e.preventDefault();
                }
              }}
              onChange={handlePhoneNumber}
            />
          </div>
        </FormControl>
        {success && (
          <FormHelperText className='text-success text-center'>
            <Translate value={'JS_SMS_MESSAGE_SUCCESS'} />
          </FormHelperText>
        )}
        {error && (
          <FormHelperText className='text-error text-center'>
            <Translate value={error} />
          </FormHelperText>
        )}

        <FormControl className='mt-2 pl-5'>
          <FormControlLabel
            className='cursor-none pointer-events-none'
            control={<Checkbox checked={defaultChecked} onChange={handleChange} />}
            label={
              <Translate value={'JS_PAYER_PHONE_CONTACT'} className={'text-14-400-22 text-black'} />
            }
          />

          <FormControlLabel
            className='cursor-none pointer-events-none'
            control={<Checkbox checked={defaultChecked} className='pt-0' onChange={handleChange} />}
            label={
              <Translate value={'JS_TEXTING_NOTIFY'} className={'text-14-400-22 text-black'} />
            }
          />
        </FormControl>

        <div className='mt-4 flex justify-center'>
          <Button
            className='text-14 rounded-full pt-[14px] pb-[14px] pl-[24px] pr-[24px]'
            variant='contained'
            onClick={() => submit()}
            disabled={!phoneNumber}
          >
            <span>
              Submit&nbsp;
              <ArrowForwardIcon />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SmsUpdate;
